import request from "@/utils/request"

// 新增周报模板
export function insertTabloid(data) {
  return request({
    url: "/tabloid/insertTabloid",
    method: "post",
    data
  })
}
//周报模板列表
export function tabloidPage(data) {
    return request({
      url: "/tabloid/tabloidPage",
      method: "post",
      data
    })
  }
//生成周报
export function insertWeeklyNewspaper(data) {
    return request({
      url: "/tabloid/insertWeeklyNewspaper",
      method: "post",
      data
    })
  }
//周报列表
export function weekTabloidPage(data) {
    return request({
      url: "/tabloid/weekTabloidPage",
      method: "post",
      data
    })
  }
//删除周报模板

export function deleteTabloidModel(query) {
  return request({
    url: "/tabloid/deleteTabloidModel",
    method: "DELETE",
    params:query
  })
}
//模板详情页
export function getTabloid(query) {
  return request({
    url: "/tabloid/getTabloid",
    method: "get",
    params:query
  })
}
//编辑周报模板
export function updateTabloidModel(data) {
  return request({
    url: "/tabloid/updateTabloidModel",
    method: "post",
    data
  })
}
//周报启用状态
export function updateWeeklyNewspaperStatus(data) {
  return request({
    url: "/tabloid/updateWeeklyNewspaperStatus",
    method: "post",
    data
  })
}
//应用模板
export function applyTabloidModel(data) {
  return request({
    url: "/tabloid/applyTabloidModel",
    method: "post",
    data
  })
}
//编辑-周报设置
export function updateTabloidSetting(data) {
  return request({
    url: "/tabloid/updateTabloidSetting",
    method: "post",
    data
  })
}
//周报设置详情
export function selectById(query) {
  return request({
    url: "/tabloid/selectById",
    method: "get",
    params:query
  })
}
//用户周报详情
export function userTabloidPage(data) {
  return request({
    url: "/weChatUser/userTabloidPage",
    method: "post",
    data
  })
}
//周报二维码
export function basicQrcode(data) {
  return request({
    url: "/weChatUser/basicQrcode",
    method: "get",
    params:data
  })
}
export function getTabloidContentNum(data) {
  return request({
    url: "/weChatUser/getTabloidContentNum",
    method: "get",
    params:data
  })
}





