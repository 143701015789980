<template>
  <div class="weekly_box">
    <div class="weekly_left">
      <div class="weekly_left_top">
        <span>周报模版</span>
      </div>
      <el-button type="primary" @click="weekedit">新增</el-button>
      <el-button v-if="listWeekly.length==0" @click="weekgenerate">生成周报</el-button>
      <div class="over_weekly">
        <div class="over_weekly_item" v-for="(item, index) in listData" :key="index">
          <div class="over_weekly_item_img">
            <img :src="item.cover" alt="" />
            <div class="po_title">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="weely_padding">
            <div class="weeklyname_h2">
              <span>{{ item.tabloidIntro }}</span>
            </div>
            <div class="weeklyname_flex">
              <div>
                <span>{{ item.userName }}</span>
              </div>
              <div>
                <span>更新于{{ item.updateTime }}</span>
              </div>
            </div>
            <div class="position_bottom">
              <div class="position_bottom_flex">
                <div>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="applyTabloidModel(item)"
                    icon="el-icon-circle-check"
                    >应用</el-button
                  >
                  <el-button size="mini" icon="el-icon-document-copy" @click="copyweekly(item)"
                    >复制</el-button
                  >
                  <el-button size="mini" icon="el-icon-edit-outline" @click="getTabloid(item)"
                    >编辑</el-button
                  >
                </div>
                <el-button
                  @click="deleteTabloidModel(item)"
                  type="text"
                  style="color: #fd6161"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="weekly_right">
      <div class="weekly_left_top">
        <span>周报设置</span>
      </div>

      <div class="weekly_left_center">
        <div class="weekly_left_top_text">
          <el-button type="primary" @click="weeklyClick">周报设置</el-button>
          <span>（基础设置：每一周（5天）生成一份周报）</span>
        </div>
        <div class="weekly_left_center_right">
          <div class="last_month" @click="getYMD('last')">
            <span>上个月</span>
            <i class="el-icon-arrow-up"></i>
          </div>
          <div style="padding: 0px 20px">
            <span>{{ nowDate }}</span>
          </div>
          <div class="next_month" @click="getYMD('next')">
            <span>下个月</span>
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div>
        <div class="over_weekly_item_right" v-loading="loading">
          <div v-for="(item, index) in listWeekly" :key="index">
            <div @click="tabitem(item)" class="over_weekly_item" :class="item.checked ? 'over_border' : ''">
              <div class="over_weekly_item_img">
                <div id="content">
                  <img :src="item.cover" alt="" />
                  <div class="po_title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div id="show"></div>
                  <div id="show_img" class="none_img" @click="easyDialogclick(item)">
                    <img
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/预览.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="weely_padding" >
                <div class="weeklyname_h2">
                  <span>{{ item.tabloidIntro }}</span>
                </div>
                <div class="weeklyname_flex">
                  <div>
                    <span>{{ item.tabloidTime }}</span>
                  </div>
                </div>
                <div class="position_bottom">
                  <div class="position_bottom_flex">
                    <div>
                      <el-button size="mini" icon="el-icon-edit-outline" @click="getTabloid(item)"
                        >编辑</el-button
                      >
                    </div>
                    <!-- <el-switch
                      @change="updateWeeklyNewspaperStatus(item)"
                      style="margin-right: 20px"
                      v-model="item.weeklyNewspaperStatus"
                      :active-text="item.weeklyNewspaperStatus ? '已开启' : '未开启'"
                    >
                    </el-switch> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button_box">
          <!-- <div class="save button">保存</div> -->
          <div class="cancel button" @click="clcans">返回上一级</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="周报设置"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="refuse">
        <el-form
          :model="paramWeek"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="是否开启周报:" prop="weeklyNewspaperStatus">
            <el-switch
            @change="weeklychange"
              style="margin-right: 20px"
              v-model="paramWeek.weeklyNewspaperStatus"
              :active-text="paramWeek.weeklyNewspaperStatus ? '已开启' : '未开启'"
            >
            </el-switch>
            <div>
              <el-checkbox-group v-model="paramWeek.userTypeList">
                <el-checkbox label="1">
                  <el-tooltip placement="bottom">
                    <div class="tooltip" slot="content">
                      <span>企业用户：绑定企业不是会员的用户</span>
                    </div>
                    <el-button class="hover_text" type="text">
                      <span>企业用户</span>
                    </el-button>
                  </el-tooltip>
                </el-checkbox>
                <el-checkbox label="2">
                  <el-tooltip placement="bottom">
                    <div class="tooltip" slot="content">
                      <span>个人会员：购买个人会员的用户</span>
                    </div>
                    <el-button class="hover_text" type="text">
                      <span>个人会员</span>
                    </el-button>
                  </el-tooltip>
                </el-checkbox>
                <el-checkbox label="3">
                  <el-tooltip placement="bottom">
                    <div class="tooltip" slot="content">
                      <span>企业会员：购买企业会员的用户</span>
                    </div>
                    <el-button class="hover_text" type="text">
                      <span>企业会员</span>
                    </el-button>
                  </el-tooltip>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="是否开启微信通知:" prop="wxNotifyStatus">
            <el-switch
              style="margin-right: 20px"
              v-model="paramWeek.wxNotifyStatus"
              :active-text="paramWeek.wxNotifyStatus ? '已开启' : '未开启'"
            >
            </el-switch>
            <div class="text_trip">
              如 <span>开启</span>微信服务通知，则
              <span>按照设置发送消息</span> ；如<span>不开启</span>微信服务通知，则<span
                >不给用户发送通知</span
              >
            </div>
          </el-form-item>
          <el-form-item label="是否开启短信通知:" prop="shortNoteStatus">
            <el-switch
              style="margin-right: 20px"
              v-model="paramWeek.shortNoteStatus"
              :active-text="paramWeek.shortNoteStatus ? '已开启' : '未开启'"
            >
            </el-switch>
            <div class="text_trip">
              如<span>开启</span>短信通知，则<span>按照设置发送消息</span>
              ；如<span>不开启</span>短信通知，则<span>不给用户发送通知</span>
            </div>
          </el-form-item>
          <el-form-item label="是否开启弹窗:" prop="popupStatus">
            <el-switch
              style="margin-right: 20px"
              v-model="paramWeek.popupStatus"
              :active-text="paramWeek.popupStatus ? '已开启' : '未开启'"
            >
            </el-switch>
            <div class="text_trip">
              如<span>开启</span>弹窗提醒，则
              <span>按照设置弹出弹窗</span> ；如<span>不开启</span>弹窗提醒，则<span
                >不给用户弹出弹窗</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="weekdialogVisible"
      width="40%"
      :before-close="handleCloseadminUser"
    >
      <div class="refuse">
        <el-form
          :model="adminUser"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="封面:" prop="cover" ref="kfFormRef">
            <img-big-upload
              :imageShow="adminUser.cover"
              :addTitleShow="false"
              @removeImg="removeImgDian"
              @uploadOneImgShow="uploadOneImgShow"
            />
            <span class="span_text">此图片应用小报封面364*169px，应小于4MB</span>
          </el-form-item>
          <el-form-item label="标题:" prop="title">
            <el-input v-model="adminUser.title" placeholder="请输入标题" />

            <span class="span_text"
              >此标题需应用在封面图上，合成一张小报图片（标准：企业简称+默认一句话）</span
            >
          </el-form-item>
          <el-form-item
            v-if="title == '编辑周报' && adminUser.tabloidTime"
            label="小报时间:"
            prop="tabloidTime"
          >
            <!-- <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="adminUser.tabloidTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker> -->
            <el-input  readonly  prefix-icon="el-icon-date" placeholder="请选择日期" v-model="adminUser.tabloidTime">
              
            </el-input>
            <div class="span_text">
              此标题需应用在封面图上，合成一张小报图片（标准：企业简称+默认一句话）
            </div>
          </el-form-item>
          <el-form-item label="一句话介绍:" prop="tabloidIntro">
            <el-input v-model="adminUser.tabloidIntro" placeholder="请输入标题" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="weekdialogVisible = !weekdialogVisible">取 消</el-button>
        <el-button type="primary" @click="addweekly">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="图片预览" width="750px" :visible.sync="easyDialogVisible" append-to-body>
      <div class="dialog_box">
        <img width="700px;" height="320px" :src="easyDialogVisibleimg" alt="" />
        <div class="span_check">
          <span>立即查看</span>
        </div>
        <div class="span_time">
          <span>({{ easyDialogtime }})</span>
        </div>
        <div class="span_name">
          <span>{{ easyDialogtitle }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  insertTabloid,
  updateWeeklyNewspaperStatus,
  updateTabloidSetting,
  applyTabloidModel,
  selectById,
  tabloidPage,
  insertWeeklyNewspaper,
  updateTabloidModel,
  getTabloid,
  deleteTabloidModel,
  weekTabloidPage
} from '@/api/weekly'
const defaultQueryInfo = Object.freeze({
  orderByParam: '',
  pageNum: 1,
  pageSize: 10
})
const defaultFaultInfo = Object.freeze({
  orderByParam: '',
  time: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'weeklymanage',
  data() {
    return {
      //新增周报模版
      adminUser: {
        cover: '', //封面图
        tabloidIntro: '', //一句话介绍
        tabloidTime: '', //小报时间,
        title: '' //标题
      },
      rules: {
        cover: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        tabloidIntro: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        tabloidTime: [{ required: true, message: '请选择小报时间', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      },

      title: '',
      easyDialogVisible: false, //预览图片
      easyDialogVisibleimg: '', //预览图片
      easyDialogtitle: '', //预览标题
      easyDialogtime: '',
      paramWeek: {
        id: '',
        popupStatus: true,
        shortNoteStatus: true, //短信通知状态，1表示开启
        userType: 0,
        userTypeList: [], //1--企业用户，2--个人会员，3--企业会员
        weeklyNewspaperStatus: true, //开启状态，1表示开启;默认新增开启
        wxNotifyStatus: true //微信通知状态，1表示开启
      },
      tabindex: 0,
      queryInfo: { ...defaultQueryInfo },
      faultInfo: { ...defaultFaultInfo },
      listData: [], //周报模版
      listWeekly: [], //一个月周报
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      easyDialog: false, //预览图片
      nowDate: '',
      myDate: '',
      myYear: '',
      myMonth: '',
      value1: false,
      dialogVisible: false, //小报设置
      weekdialogVisible: false
    }
  },
  created() {
    this.myDate = new Date()
    this.myYear = this.myDate.getFullYear() //获取完整的年份(4位,1970-????)
    this.myMonth = this.myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)

    this.getYMD()
    this.search()
  },
  methods: {
    async search() {
      this.loading = true
      const { data: res } = await tabloidPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.weekTabloidPage()
        this.listData = res.data.list

        this.total = res.data.total
        this.loading = false
      }
    },
    async weekTabloidPage() {
      this.loading = true
      const { data: res } = await weekTabloidPage(this.faultInfo)
      if (res.resultCode == 200) {
        this.listWeekly = res.data.list
        this.listWeekly.forEach((item) => {
          this.$set(item, 'checked', false)
        })
        this.total = res.data.total
        this.loading = false
      }
    },
    weeklychange(val){
     if(!val){
      this.paramWeek.userTypeList=[]
     }
    },
    //生成周报
    weekgenerate() {
      let ids = []
      // this.listData.forEach((el) => {
      //   ids.push(el.id)
      // })
      this.$alert('您是否确认生成周报？')
        .then(function () {
          return insertWeeklyNewspaper(ids)
        })
        .then((res) => {
          if (res.data.resultCode === 200) {
            this.weekTabloidPage()
            this.$message.success('生成成功')
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {})
    },
    //取消新增周报
    handleCloseadminUser(){
      this.weekdialogVisible=false
    },
    //删除周报模版
    deleteTabloidModel(item) {
      const query = {
        id: item.id
      }
      this.$alert('您是否确认删除周报模版？')
        .then(function () {
          return deleteTabloidModel(query)
        })
        .then((res) => {
          if (res.data.resultCode === 200) {
            this.search()
            this.$message.success('删除成功')
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {})
    },
    //模版详情
    async getTabloid(item) {
      this.weekdialogVisible = true
      this.title = '编辑周报'
      const { data: res } = await getTabloid({ id: item.id })
      if (res.resultCode == 200) {
        this.adminUser = res.data
      }
    },
    //新增周报模版
    weekedit() {
      this.weekdialogVisible = true
      this.title = '新增周报模版'
      this.adminUser = {
        cover: '', //封面图
        tabloidIntro: '', //一句话介绍
        title: '' //标题
      }
    },
    //复制现有周报
    copyweekly(item) {
      const query = {
        cover: item.cover, //封面图
        tabloidIntro: item.tabloidIntro, //一句话介绍
        title: item.title //标题
      }
      insertTabloid(query).then((response) => {
        if (response.data.resultCode === 200) {
          this.$message.success('复制成功')
          this.search()
        }
      })
    },
    //保存周报
    addweekly() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            insertTabloid(this.adminUser).then((response) => {
              if (response.data.resultCode === 200) {
                this.$message.success('保存成功')
                this.weekdialogVisible = false
                this.search()
              }
            })
          } else {
            updateTabloidModel(this.adminUser).then((response) => {
              if (response.data.resultCode === 200) {
                this.$message.success('编辑成功')
                this.weekdialogVisible = false
                this.search()
              }
            })
          }
        }
      })
    },
    //启动关闭
    async updateWeeklyNewspaperStatus(item) {
      const query = {
        id: item.id,
        value: item.weeklyNewspaperStatus
      }
      const { data: res } = await updateWeeklyNewspaperStatus(query)
      if (res.resultCode === 200) {
        this.weekTabloidPage()
        this.$message.success('操作成功')
      }
    },
    //打开周报弹窗
    async weeklyClick() {
      if (this.listWeekly.length > 0) {
        const { data: res } = await selectById({ id: this.listWeekly[0].id })
        if (res.resultCode === 200) {
          this.paramWeek = res.data
          this.dialogVisible = true
        }
      } else {
        this.$message.error('请先生成周报!')
      }
    },
    //预览图片
    easyDialogclick(item) {
      this.easyDialogVisible = true
      this.easyDialogVisibleimg = item.cover
      this.easyDialogtitle = item.title
      this.easyDialogtime = []
      let str = item.tabloidTime
      str = str.split('-')
      str.forEach((el) => {
        el = el.slice(el.indexOf('年') + 1, el.length - 1)
        el = el.replace(new RegExp('月', 'g'), '.')
        this.easyDialogtime.push(el)
      })
      this.easyDialogtime = this.easyDialogtime.join('-')
    },
    //取消周报弹窗
    handleClose() {
      this.dialogVisible = false
    },
    //点击生成的周报
    tabitem(item) {
      item.checked = !item.checked
    },
    //保存周报设置
    async submit() {
      this.paramWeek.ids = []
      this.listWeekly.forEach((el) => {
        this.paramWeek.ids.push(el.id)
      })
      const { data: res } = await updateTabloidSetting(this.paramWeek)
      if (res.resultCode === 200) {
        this.dialogVisible = false
        this.$message.success('保存成功')
      }
    },
    clcans() {
      this.$router.go(-1)
    },
    //应用
    async applyTabloidModel(item) {
      const query = {
        ids: [],
        modelId: item.id
      }

      this.listWeekly.forEach((el) => {
        if (el.checked) {
          query.ids.push(el.id)
        }
      })
      if (!query.ids.length) {
        this.$message.error('请先选择周报！')
        return
      }
      const { data: res } = await applyTabloidModel(query)
      if (res.resultCode === 200) {
        this.$message.success('应用成功')
        this.weekTabloidPage()
      }
    },
    //获取时间
    getYMD(str) {
      if (str == 'last') {
        if (this.myMonth <= 1) {
          this.myYear = this.myYear - 1
          this.myMonth = 12
        } else {
          this.myMonth = this.myMonth - 1
        }
      }
      if (str == 'next') {
        if (this.myMonth >= 12) {
          this.myYear = this.myYear + 1
          this.myMonth = 1
        } else {
          this.myMonth = this.myMonth + 1
        }
      }
      let myMonth = this.myMonth
      this.faultInfo.time = this.myYear + '年' + myMonth + '月'
      this.weekTabloidPage()
      myMonth = myMonth > 9 ? myMonth : '0' + myMonth
      this.nowDate = this.myYear + '年' + myMonth + '月'
    },
    //获取长地址图片
    uploadOneImgShow(val) {
      this.adminUser.cover = val
      this.$refs.kfFormRef.clearValidate('cover');
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.adminUser.cover = ''
    }
  }
}
</script>

<style lang="less" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 50px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #d9d9d9;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d9d9d9;
}
.weekly_box {
  width: 100%;
  display: flex;
  height: 100%;
  .text_trip {
    span {
      color: #4e93fb;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .weekly_left {
    padding: 20px;
    width: 400px;
    background: #fff;
    .over_weekly {
      overflow: auto;
      margin-top: 15px;
      height: 90%;
      .over_weekly_item {
        margin-bottom: 20px;
        margin-left: 2px;
        height: 316px;
        width: 364px;
        background: #fff;
        position: relative;
        border-radius: 0px 0px 4px 4px;
        box-shadow: 0px 0px 2px 0px rgba(161, 161, 161, 0.5);
        cursor: pointer;
        .weely_padding {
          padding: 0px 10px;
        }
        .position_bottom {
          position: absolute;
          bottom: 0px;
          .position_bottom_flex {
            width: 360px;
            display: flex;
            justify-content: space-between;
          }
        }
        .weeklyname_h2 {
          color: #151515;
          padding: 10px 0px;
          font-weight: 550;
        }
        .weeklyname_flex {
          display: flex;
          justify-content: space-between;
          color: #999999;
          font-size: 12px;
          padding-bottom: 10px;
        }

        .over_weekly_item_img {
          position: relative;
          width: 364px;
          height: 170px;

          .po_title {
            position: absolute;
            color: #fff;
            top: 50px;
            left: 20px;
            font-size: 20px;
            font-weight: 550;
          }
          img {
            width: 360px;
            height: 170px;
          }
        }
      }
    }
  }
  .weekly_right {
    padding: 20px;
    margin-left: 20px;
    flex: 1;
    background: #fff;
    position: relative;

    .button_box {
      position: absolute;
      bottom: 0px;
      width: 100%;
      // height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #ececec;
      padding-top: 15px;
      padding-bottom: 15px;
      .send {
        background-color: #f79646;
        color: #fff;
        cursor: pointer;
      }
      .preview {
        background-color: #37cc7e;
        color: #fff;
        cursor: pointer;
      }
      .save {
        background-color: #4e93fb;
        color: #fff;
        cursor: pointer;
      }
      .cancel {
        background-color: #fff;
        color: #4e93fb;
        cursor: pointer;
        border: 1px solid #4e93fb;
      }
      .button {
        width: 112px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
    }
    .over_weekly_item_right {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .over_weekly_item {
        margin-bottom: 20px;
        margin-left: 2px;
        height: 272px;
        width: 272px;
        background: #fff;
        position: relative;
        border-radius: 0px 0px 4px 4px;
        box-shadow: 0px 0px 2px 0px rgba(161, 161, 161, 0.5);
        margin-right: 20px;
        cursor: pointer;
        .weely_padding {
          padding: 0px 10px;
        }
        .position_bottom {
          position: absolute;
          bottom: 15px;
          .position_bottom_flex {
            width: 272px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .weeklyname_h2 {
          color: #151515;
          padding: 10px 0px;
          font-weight: 550;
        }
        .weeklyname_flex {
          display: flex;
          justify-content: space-between;
          color: #999999;
          font-size: 15px;
          padding-top: 10px;
        }

        .over_weekly_item_img {
          position: relative;
          width: 272px;
          height: 126px;

          #content {
            width: 272px;
            height: 126px;
            position: relative;
          }
          #show {
            width: 272px;
            height: 126px;
            background: #4a4c4d;
            top: 0px;
            position: absolute;
            opacity: 0;
            display: block;
            font-size: 18px;
            color: #ffffff;
            transition: 0.3s;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            z-index: 999;
          }
          #content:hover #show {
            color: #656e73;
            opacity: 0.5;
          }
          .none_img {
            display: none;
          }
          #content:hover #show_img {
            display: block;
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 36px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999 !important;
            opacity: 1;
            img {
              width: 18px;
              height: 16px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 1;
              z-index: 9999 !important;
            }
          }
          .po_title {
            position: absolute;
            color: #fff;
            top: 30px;
            left: 20px;
            font-size: 18px;
            font-weight: 550;
          }
          img {
            width: 268px;
            height: 126px;
          }
        }
      }
      .over_border {
        border: 2px solid #4e93fb;
        width: 268px;
        height: 268px;
      }
    }

    .weekly_left_center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .weekly_left_top_text {
        display: flex;
        align-items: flex-end;
        color: #999999;
      }
      .weekly_left_center_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .last_month,
        .next_month {
          width: 80px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border: 1px solid #d9ddec;
          color: #778ca2;
          cursor: pointer;
        }
      }
    }
  }
  .weekly_left_top {
    padding-bottom: 20px;
  }
  .span_text {
    color: #b8b8b8;
    font-size: 12px;
  }
}
.dialog_box {
  position: relative;
  .span_check {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 120px;
    height: 48px;
    background: #fff;
    text-align: center;
    line-height: 48px;
    border-radius: 20px;
    color: #4e93fb;
    font-size: 20px;
    font-weight: 550;
  }
  .span_time {
    position: absolute;
    left: 20px;
    top: 20px;
    color: #fff;
    font-size: 20px;
  }
  .span_name {
    position: absolute;
    left: 20px;
    top: 60px;
    font-size: 28px;
    color: #fff;
    font-weight: 550;
  }
}
</style>
